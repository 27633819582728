<template>
    <div class="o365-header-group-cell o365-header-cell o365-header-cell-container" :class="{'group-cell': group.uid}"
        :style="{width: group.width+'px', left: group.left+'px'}" :o365-header-group-id="group.groupId"
        :o365-field="group.field" :o365-header-group="`${groupRowIndex}-${groupIndex}`" :draggable="!group.suppressMovable">
        <div v-if="group.resizable" class="o365-sizer sizer-right" :o365-field="group.field"
            :o365-header-group="`${groupRowIndex}-${groupIndex}`"></div>
        <div class="o365-header-cell-label-container text-truncate">
            <ODropdown v-if="!group.disableMenu && group.groupId" placement="bottom-end">
                <template #default="scope">
                    <span class="o365-header-cell-menu mx-1" :ref="scope.target" @click="scope.open">
                        <i class="bi bi-list"></i>
                    </span>
                </template>
                <template #dropdown="scope">
                    <div class="card shadow dropdown-menu rounded-0 o365-header-dropdown"
                        style="position: absolute; z-index: 10000; min-width: 200px;" :ref="scope.container">
                            <button class="dropdown-item" @click="()=>{group.hide = true; scope.close(); }">{{$t('Hide Group')}}</button>
                            <!-- <div class="dropdown-divider"></div> -->
                            <!-- <ODropdown v-if="group.children?.length" placement="right"> -->
                                <!-- <template #default="colScope"> -->
                                    <!-- <button class="dropdown-item" href="javascript:void(0)" :ref="colScope.target" -->
                                        <!-- @click="colScope.open">{{$t('Columns')}} -->
                                        <!-- <i class="bi bi-caret-right-fill float-end"></i> -->    
                                    <!-- </button> -->
                                <!-- </template> -->
                                <!-- <template #dropdown="pinScope"> -->
                                    <!-- <div :ref="pinScope.container" class="card shadow dropdown-menu rounder-0"> -->
                                        <!-- <button class="dropdown-item" v-for="col in group.children" -->
                                            <!-- @click="()=>{ col.hide = !col.hide; scope.close();}"> -->
                                            <!-- <i class="bi bi-check2" :style="{'color': !col.hide ? null : 'transparent'}"></i> -->
                                            <!-- {{$t(col.headerName)}} -->
                                        <!-- </button> -->

                                    <!-- </div> -->
                                <!-- </template> -->
                            <!-- </ODropdown> -->
                            <div class="dropdown-divider"></div>
                               <button class="dropdown-item"
                                @click="dataGridControl.resetLayout();scope.close();">{{$t('Reset Layout')}}</button>
                    </div>
                </template>
            </ODropdown>
            <div class="o365-header-cell-label">
                <component v-if="group.groupId  && group.headerTextSlot" :is="group.headerTextSlot" :column="group"></component>
                <span v-else class="o365-header-cell-text text-truncate">{{group.groupId ? group.headerName : ''}}</span>  
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ODropdown } from 'o365-ui-components';
import { InjectionKeys } from 'o365-utils';
import { inject } from 'vue';

export interface IProps {
    group: any,
    groupIndex?: number,
    groupRowIndex?: number
};

const props = defineProps<IProps>();

const dataGridControl = inject(InjectionKeys.dataGridControlKey, null);
</script>
